import moment from 'moment-timezone'

export const minDateCallback = (cruiseStartDate: string) => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  return moment.utc(cruiseStartDate) < moment.utc()
    ? moment.utc().format('YYYY-MM-DD')
    : moment.utc(cruiseStartDate).format('YYYY-MM-DD')
}

export const maxDateCallback = (cruiseEndDate: string) => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  return moment.utc(cruiseEndDate) > moment.utc()
    ? moment.utc(cruiseEndDate).format('YYYY-MM-DD')
    : moment.utc().format('YYYY-MM-DD')
}

/** Get date to render passing timezone (usually port timezone) and date to render */
export const calculateDateToRender = (timezone: string, date: string) => {
  let newTimeZone = timezone
  if (timezone === 'Alaska') {
    newTimeZone = 'America/Anchorage'
  }
  const machineTimezone = moment.tz.guess()
  const calculatedTimezone = newTimeZone || machineTimezone
  return `${moment.utc(date).format('MMM Do, YYYY h:mm A ')}${moment()
    .tz(calculatedTimezone)
    .format('(z)')}`
}

export const parseDateToString = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  }

  const dateFormatter = new Intl.DateTimeFormat('en-US', options)
  return dateFormatter.format(date)
}
