import moment from 'moment-timezone'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { SwitchProps } from '@mui/material'
import { IAppointment } from '../../types/appointments'
import { WAIT_MINUTES_BAD, WAIT_MINUTES_TOO_BAD } from '../../utils/constants'

const DateTimeColumn: React.FC<{ appointment: IAppointment }> = ({ appointment }) => {
  let timezone =
    appointment.port && appointment.port.timezone
      ? appointment.port.timezone
      : Intl.DateTimeFormat().resolvedOptions().timeZone
  if (timezone === 'Alaska') {
    timezone = 'America/Anchorage'
  }
  const apptQueuedOn = moment(appointment.queued_on).utc()
  const apptServerTime = moment(appointment.serverTime)

  let chipColor: SwitchProps['color'] = 'default'

  const calcTimeAgo = () => {
    const minutesAgo = Math.floor(apptServerTime.diff(apptQueuedOn) / 1000 / 60)
    if (minutesAgo >= WAIT_MINUTES_BAD && minutesAgo < WAIT_MINUTES_TOO_BAD) chipColor = 'warning'
    if (minutesAgo >= WAIT_MINUTES_TOO_BAD) {
      chipColor = 'error'
    }
  }
  if (appointment.type === 'QUEUED') calcTimeAgo()

  return (
    <>
      <Typography variant="body2">
        {`${apptQueuedOn.format('MMM Do h:mm A')} 
          ${moment().tz(timezone).format('(z)')}`}
      </Typography>
      <Typography variant="body2">{appointment?.port?.name}</Typography>
      {appointment.type === 'QUEUED' && appointment.status === 'PENDING' && (
        <Chip
          size="small"
          icon={<AccessTimeIcon />}
          color={chipColor}
          label={apptQueuedOn.from(apptServerTime)}
          sx={{ mt: 1 }}
        />
      )}
    </>
  )
}

export default DateTimeColumn
