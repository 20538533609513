import Typography from '@mui/material/Typography'
import moment from 'moment-timezone'
import { IAppointment } from '../../types/appointments'

const LastActionColumn: React.FC<{ appointment: IAppointment }> = ({ appointment }) => {
  let timezone =
    appointment.port && appointment.port.timezone
      ? appointment.port.timezone
      : Intl.DateTimeFormat().resolvedOptions().timeZone
  if (timezone === 'Alaska') {
    timezone = 'America/Anchorage'
  }
  const lastActionDate =
    moment(appointment.recorded_on).format('MMM Do ') +
    moment(appointment.recorded_on).format('h:mm A ') +
    moment().tz(timezone).format('(z)')

  return (
    <>
      <Typography variant="body2">{lastActionDate}</Typography>
      {appointment.last_action_comment}
    </>
  )
}

export default LastActionColumn
